import { useAuthStore } from '@/stores/authStore';
// Middleware to check whether Email is validated, otherwise you have to validate first before proceeding to protected pages
export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore();
  const { user } = useAuthStore();
    if(user) {
        if(user.attributes['email-verified-at'] === null) {
          return navigateTo('/email-verificatie');
        } else if(user.attributes['phone-number-verified-at'] === null) {
          return navigateTo('/telefoon-verificatie');
        }
    } else {

    }
});